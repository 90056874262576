import React, {useState,useEffect} from "react";
import './index.scss'
import getParamsNew from '../../utils/getUrlParams'
import {  post } from '../../api/api.js'
const MenuType = () => {
  const [typeData, setTypeData] = useState([])
  const shopId = getParamsNew('id');
  async function getMenuType() {
    const res = await post(`/shopCatalogManager/queryDataList?shopId=${shopId}`);
    const data = res.data;
    setTypeData(data)
  }
  useEffect(() => {
    getMenuType()
  }, [])

  function goTypeList(item) {
    const obj = {
      "type": 'homePage',
      "target": `/MenuList?id=${item.id}`,
      "isNeedLogin": false,
    }
    const newObj = JSON.stringify(obj)
    window.jsObj.sendJumpInfo(newObj);
  }
    return(
      <div className="MenuType">
          <div className="menu-box">
            {
              typeData.map((item,index) => {
                return <div className="menu-item" key={index} onClick={goTypeList.bind(this,item)}>
                  <img className="menu-itemImg" src={item.catPic || ''} alt="" />
                  <span className="menu-itemText">{item.name}</span>
                </div>
              })
            }
             
          </div>
      </div>
    )
}
export default MenuType;