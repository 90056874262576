import axios from 'axios';
const url = process.env.REACT_APP_URL;
const instance = axios.create({
  baseURL: url, // 设置基本URL
  timeout: 5000, // 设置请求超时时间
});
instance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    config.headers.Authorization = sessionStorage.getItem('token'); // 添加请求头
    return config;
  },
  error => {
    // 处理请求错误
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    // 处理响应错误
    return Promise.reject(error);
  }
);
export const get = (url, params) => {
  return instance.get(url, { params });
};

export const post = (url, data) => {
  return instance.post(url, data);
};

