import React from "react";
import './index.scss'
const construct = require('../../assets/image/construct@2x.png') 
const RevitalizationMall: React.FC = (props) => {
  return (
    <div className="RevitalizationMall">
        <div className="jian">
            <img src={construct} alt="" />
            <br></br>
            <span>正在建设中......</span>
        </div>
    </div>
  )
}
export default RevitalizationMall;