import React from 'react';
import Masonry from 'react-masonry-css';
import Goods from '../goods/index.jsx'
import './index.scss'
const MasonryLayout = (props) => {
  const breakpointColumnsObj = { // 简配配置，还不知道要求
    default: 2,
    1100: 2,
    700: 2,
    500: 2
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {
          props.data.map((cardItem,index) => {
              return <Goods key={index} data={cardItem}></Goods>
          })
      }
    </Masonry>
  );
};

export default MasonryLayout;