import React, { Component } from 'react'
import { Ellipsis } from 'antd-mobile'
import './index.scss'

export default class Scroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeList: [
        {
          key: 1,
          text: '欢迎访问和美乡村公益民生汇'
        },
      ],
      animate: false,
    }
  }

  //页面加载的时候，设置一个永恒的定时器，1.5s后就会执行定时器中的逻辑
  componentDidMount() {
    setInterval(() => {
      this.setState({
        animate: true
      })
      this.changeAnim()
    }, 2500);
  }

  //在setInterval执行中，会调用该函数，在内部会设置一个一次性的定时器，每次都会将数组的第一个元素添加到数组的最后，并且将数组的第一个元素删除，
  changeAnim = () => {
    const { noticeList } = this.state
    setTimeout(() => {
      noticeList.push(noticeList[0]);
      noticeList.shift();
      this.setState({
        noticeList,
        animate: false
      })
    }, 500)
  }


  render() {
    const { noticeList, animate } = this.state;
    return (
      <div className="scrollPage">
        <div className="scrollWrapper">
          <ul className={animate ? 'anim' : ''}>
            {
              noticeList.map((item, index) => {
                return <li key={index}>
                  <Ellipsis direction='end' content={item.text} />
                </li>
              })
            }
          </ul>
        </div>
      </div>
    )
  }

}

