import { Switch, Route, Redirect } from 'react-router-dom'
function RouterView({ routes }) {
    const routerArr = routes && routes.filter(item => !item.to);//非重定向的数组
    const redirectArr = routes && routes.filter(item => item.to);//重定向的数组
    return <Switch>
        {
            routerArr && routerArr.map((item, index) => <Route key={index} path={item.path} render={(props) => {
                document.title = item.title || "易致富商城";
                return <item.component {...props} child={item.children} />
            }} />)
        }
        {
            redirectArr && redirectArr.map((item, index) => <Redirect key={index} from={item.path} to={item.to} />)
        }
 
    </Switch>
}
 
export default RouterView;