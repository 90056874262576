import React, {useState,useEffect} from "react";
import './index.scss'
// import Swiper from '../../components/swiperVideo/Swiper';
import Swiper from '../../components/swiper'
import getParamsNew from '../../utils/getUrlParams'
import {get} from '../../api/api.js'
import { useHistory } from 'react-router-dom';
const genuine = require('../../assets/image/genuine@2x.png') 
const examine = require('../../assets/image/examine@2x.png') 
const afterSale = require('../../assets/image/afterSale@2x.png') 

const GoodsDetail: React.FC = (props) => {
  let [data,setData] = useState([]) //总数据
  let [detailImg,setDetailImg] = useState([]) //轮播图
  let [shopDetail,setShopDetail] = useState([]) // 商品详情图片
  useEffect(() => {
    const id = getParamsNew('id')
    const params = {
      id:id
    }
    get('/product/selectByPrimaryKey',params).then(res => {
      if(res && res.data){
        const imgDetil = res.data.carouselPicture.split(',')
        setShopDetail(res.data.details)
        // imgDetil.unshift(res.data.productVideo)
        setDetailImg(imgDetil)
        setData(res.data)
      }
      
    })
  },[])
  const history = useHistory();
  return (
    <div className="goodsDetail">
        <div className="goodsDetail-main">
            <div className="goodsDetail-swiper">
              <Swiper
                imgArr= {detailImg}
              ></Swiper>
            </div>
            <div className="goodsDetail-goodDesc">
              <span className="goodsDetail-goodName">{data.name}</span>
              <br />
              <div className="goodsDetail-price">
            <span className='goodsDetail-bidPrice'>{data.maxPoints} <span className="integral">石榴籽</span> </span>
              <span className='goodsDetail-exchange'>已兑{data.saleCount ? data.saleCount : '0'}件</span>
              </div>
              <div className="goodsDetail-specs">
                <div className="goodsDetail-specsContent">
                    <span>
                      规格: &nbsp;&nbsp;&nbsp;&nbsp; 
                    </span>
                    <span>
                      已选: 白色 + 贴纸
                    </span>
                    <i style={{marginLeft: 'auto'}} className="iconfont icon-youjiantou"></i>
                </div>
              </div>
              <div className="goodsDetail-sendGoods">
                <div className="goodsDetail-sendGoodsContent">
                  <div>
                        发货: &nbsp;&nbsp;&nbsp;&nbsp; 
                        <span className="goodsDetail-sendGoodInfo">
                            {data.belongRegionName} &nbsp;&nbsp; 快递&nbsp;&nbsp; 包邮
                        </span>
                  </div>
                </div>
              </div>
              <div className="goodsDetail-goodInfo">
                <div className="goodsDetail-goodInfoContent">
                  <div className="goodsDetail-goodInfoItem">
                      <img src={genuine} style={{width: '32px',height: '32px'}} alt="" />
                      <span>正品保障</span>
                  </div>
                  <div className="goodsDetail-goodInfoItem">
                      <img src={examine} style={{width: '32px',height: '32px'}} alt="" />
                      <span>验货签收</span>
                  </div>
                  <div className="goodsDetail-goodInfoItem">
                      <img src={afterSale} style={{width: '32px',height: '32px'}} alt="" />
                      <span>优质售后</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="goodsDetail-Detail">
                <div className="goodsDetail-detailTitle">
                    <hr style={{width: '30%'}}/>
                    <span className="goodsDetail-detailTitleText" >商品详情</span>
                    <hr style={{width: '30%'}}/>
                </div>
                <div className="goodsDetail-DetailImg">
                    {/* {
                      shopDetail && shopDetail.map((item,index) => {
                        return <img style={{width: '100%'}} src={item} key={index} alt=""></img>
                      })
                    } */}
                    <div dangerouslySetInnerHTML={{ __html: shopDetail }}>

                    </div>
                </div>
            </div>
        </div>
        <div className="goodsDetail-footer" onClick={ExchangeClick.bind(this)}>
            <span className="goodsDetail-nowExchange" >立即兑换</span>
        </div>
    </div>
  )
  function ExchangeClick(){
      const obj = {
        "target": `/ConfirmOrder?id=${data.id}`,
        "isNeedLogin": true,
      }
      const newObj = JSON.stringify(obj)
      window.jsObj.sendJumpInfo(newObj);
    }
}
export default GoodsDetail;