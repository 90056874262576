import React from 'react';
import { Card } from 'antd-mobile'
import './index.scss'
const Goods:React.FC = (props) => {

  const {data} = props;
  const onClickGood = () => {
      const obj = {
        "type": 'homePage',
        "target": `/GoodsDetail?id=${data.id}`,
        "isNeedLogin": false,
      }
      const newObj = JSON.stringify(obj)
     window.jsObj.sendJumpInfo(newObj);
  }
  return(
    <Card 
      onClick={onClickGood}
    >
      <div className="cardContentImg">
          <img style={{width: '100%', height: '368px' ,borderRadius: '16px 16px 0 0',objectFit: 'cover'
      }} src={data.firstPicture} alt="" />
          <div className="cardContentImg-desc">
             <span className='cardContentImg-shopName'>{data.name}</span> <br />
             <div className="cardContentImg-price">
            <span className='cardContentImg-bidPrice'>{data.maxPoints} <span className='cardContentImg-integral'>石榴籽</span></span>
              <span className='cardContentImg-exchange'>已兑{data.saleCount ? data.saleCount : '0'}件</span>
             </div>
          </div>
      </div>
    </Card>)
}


export default Goods;