import React, {useState,useEffect,useCallback} from 'react';
import './index.scss'
import debounce from '../../utils/debounce';
import Swiper from '../../components/swiper/index'
import Scroll from '../../components/scroll';
import { SearchBar ,Avatar} from 'antd-mobile'
import MasonryLayout from '../GoodsConfig/masonry/index'
import axios from 'axios';
import {get, post} from '../../api/api.js'
import getParamsNew from '../../utils/getUrlParams'
import { alert } from 'antd-mobile/es/components/dialog/alert';
const triangle = require('../../assets/image/triangle@2x.png') 
const marshalling = require('../../assets/image/marshalling@2x.png')
const gong = require('../../assets/image/gongyi@2x.png')
const mall = require('../../assets/image/mall@2x.png')
const arrowLeft = require('../../assets/image/arrow-left@2x.png')
const mallIcon = require('../../assets/image/振兴商城@2x.png')
const type = require('../../assets/image/全部分类@2x.png')
const welfare = require('../../assets/image/公益榜@2x.png')
const userPeople = require('../../assets/image/个人中心@2x.png')
const Notice = require('../../assets/image/编组 9@2x.png')
const MallHomePage : React.FC = (props) => {
  let [imgData, setImgData] = useState([])
  let [shopData, setShopData] = useState([])
  let [currData, setCurrData] = useState([])
  let [searValue,setSearchValue] = useState('')
  let [currentPage, setCurrentPage] = useState(1);
  let [totalPages, setTotalPages] = useState(null)
  let [navFlag, setNavFlag] = useState(false)
  let [shopId, setShopId] = useState(null)
  const [pointsTotal,setPointsTotal] = useState(0)
  let [userInfo, setUserInfo] = useState({
    nickName: '',
    portraitPath: '',
    point: null,
  })
  const delayedQuery = useCallback(debounce(q => sendQuery(q), 800), [])
  // 判断是否登陆，及登陆后拿到总石榴籽数量
  useEffect(() => {
    const token = getParamsNew('token')
    if(token === null || token === undefined || !token){
      setNavFlag(false);
    }else {
      sessionStorage.setItem('token',token)
      get('/login/getCurUserInfo').then(res => {
        if(res.success) {
          setUserInfo({
            ...userInfo,
            nickName: res.data.loginUser.nickName,
            portraitPath: res.data.loginUser.portraitPath
        })
        setNavFlag(true)
        } else {
          setNavFlag(false)
        }
      });
      post('/user/queryAccrue').then(res => {
        if(res.success) {
          setPointsTotal(res.data.pointsTotal)
        }
      })
    }
  },[])
  // 获取当前地区店铺及轮播图的数据
   useEffect(() => {
    post('/shop/selectByBelongRegion').then(res => {
      const params = {
        shopNo: res.data.shopNo
      }
      setShopId(res.data.shopId)
      get('/shop/selectExtByShopNo', params).then(res => {
        setImgData(res.data.carouselList)
      })
    })
   },[])
    // 发起请求获取分页数据
    async function fetchProducts(){
      const res = await post(`/product/queryExtDataListPage?status=UP&currentPage=${currentPage}`)
      const data =  res.data;
      const total = res.count;
      setTotalPages(total)
      setCurrData(data)
      setShopData((shopData) => [...shopData, ...data]);
    };
    // 第一次拉取分页信息
    useEffect(() => {
        fetchProducts()
    }, [])
    // 监听scroll事件
   useEffect(() => {
      let MallHomePage = document.querySelector('.MallHomePage-box')
      MallHomePage.addEventListener('scroll', handleScroll);
    return () => {
      MallHomePage.removeEventListener('scroll', handleScroll);
    };
    }, []);
     // 判断是否滚动到页面底部
    const handleScroll = () => {
    let MallHomePage = document.querySelector('.MallHomePage-box')
    const { scrollTop, clientHeight, scrollHeight } = MallHomePage;
    if (Math.round(scrollTop + clientHeight) >= scrollHeight) {

      if((currentPage - 1) * 10 + currData.length == totalPages){
      } else {
        setCurrentPage(currentPage++);
        fetchProducts()
      }
      
    }
  };
    // 数据查询
    function searchGood(e){
        setSearchValue(e)
        delayedQuery(e)
   }
   const sendQuery = (q) => {
    post(`/product/queryExtDataListPage?name=${q}`).then(res => {
      setShopData(res.data)
    })
  };
  function goLogin() {
      const obj = {
        "type": 'userInfo',
        "target": `/MallHomePage`,
        "isNeedLogin": true,
      }
      const newObj = JSON.stringify(obj)
      window.jsObj.sendJumpInfo(newObj);
  }
    return(
       <div className='MallHomePage'>
        {
          navFlag ? <div className="MallHomePage-headSculptue">
            <div className="head-left">
              <Avatar className='MallHomePage-AvatarImg' style={{'--border-radius': '50%'}} src={userInfo.portraitPath} />
              <div className='head-leftName'>
                <div className='head-Name'>{userInfo.nickName}</div>
                <div className='head-points'>{pointsTotal}石榴籽</div>
              </div>
            </div>
            <div className="head-icon">
              <div className="MallHomePage-search">
                <SearchBar style={{ '--height': '72px', '--border-radius': '16px', }} onChange={searchGood} value={searValue} placeholder='搜索商品名称' />
              </div>
            </div>
          </div> : <div className="MallHomePage-headSculptue">
              <div className="head-left" onClick={goLogin.bind(this)}>
                <Avatar style={{ '--border-radius': '50%' }} className='MallHomePage-AvatarImg' src='' />
                <div className='head-leftName'>
                  <div className='head-Name'>未登陆</div>
                  <div className='head-points'>{pointsTotal}石榴籽</div>
                </div>
            </div>
            <div className="head-icon">
                <div className="MallHomePage-search">
                  <SearchBar style={{ '--height': '72px', '--border-radius': '16px', }} onChange={searchGood} value={searValue} placeholder='搜索商品名称' />
                </div>
            </div>
          </div>
        }
          <div className="MallHomePage-box">
            <div className="MallHomePage-nav">
              
              <div className="MallHomePage-banner">
                <Swiper
                  imgArr={imgData}
                  borderRadius='8px'
                ></Swiper>
              </div>
              <div className="MallHomePage-Notice">
                <div className="Notice-left">
                  <img className='MallHomePageGong' src={Notice} alt="" />
                </div>
                <div className="Notice-right">
                  <Scroll></Scroll>
                </div>
              </div>
              <div className="MallHomePage-menu">
                <div className="MallHomePage-menuItem" onClick={goRevitailzationMall.bind(this)}>
                  <img className='menuItem-icon' src={mallIcon} alt="" />
                  <span className='itemSpan'>振兴商城</span>
                </div>
                <div className="MallHomePage-menuItem" onClick={goMenuType.bind(this)}>
                  <img className='menuItem-icon' src={type} alt="" />
                  <span className='itemSpan'>全部分类</span>
                </div>
                <div className="MallHomePage-menuItem" onClick={goPublicWelfare.bind(this)}>
                 <img className='menuItem-icon' src={welfare} alt="" />
                  <span className='itemSpan'>公益榜</span>
                </div>
              <div className="MallHomePage-menuItem" onClick={GoPerson.bind(this)}>
                  <img className='menuItem-icon' src={userPeople} alt="" />
                  <span className='itemSpan'>个人中心</span>
                </div>
              </div>
              {/* <div className="MallHomePage-type">
                <div className="MallHomePage-typeItem">
                  <span>全部类别</span>
                  <img className='MallHomePage-itemIcon' src={triangle} alt="" />
                </div>
                <div className="MallHomePage-typeItem">
                  <span>热门排序</span>
                  <img className='MallHomePage-itemIcon' src={triangle} alt="" />
                </div>
                <div className="MallHomePage-typeItem">
                  <img className='MallHomePage-marshalling' src={marshalling} alt=''></img>
                <span style={{
                  color: '#606366',fontSize: '28px',marginLeft:'12px' }}>切换列表</span>
                </div>
              </div> */}
            </div>
            <div className="MallHomePage-main">
              <MasonryLayout data= {shopData}></MasonryLayout>
            </div>
          </div>
       </div>
    );
    function goRevitailzationMall (){
      const obj = {
        "type": 'homePage',
        "target": `/RevitalizationMall`,
        "isNeedLogin": false,
      }
      const newObj = JSON.stringify(obj)
      window.jsObj.sendJumpInfo(newObj);
    }
    function goMenuType() {
      const obj = {
        "type": 'homePage',
        "target": `/MenuType?id=${shopId}`,
        "isNeedLogin": false,
      }
      const newObj = JSON.stringify(obj)
      window.jsObj.sendJumpInfo(newObj);
    }
  function goPublicWelfare() {
    const obj = {
      "type": 'homePage',
      "target": `/PublicWelfare`,
      "isNeedLogin": false,
    }
    const newObj = JSON.stringify(obj)
    window.jsObj.sendJumpInfo(newObj);
  }
  function GoPerson () {
    const obj = {
      "type": 'myOrderList',
      "isNeedLogin": true,
    }
    const newObj = JSON.stringify(obj)
    window.jsObj.sendJumpInfo(newObj);
  }
}
export default MallHomePage;