import React, {useState, useEffect} from "react";
import './index.scss'
import { TextArea } from 'antd-mobile'
import getParamsNew from '../../utils/getUrlParams'
import {get,post} from '../../api/api.js'
// DotLoading
import { Toast } from 'antd-mobile'
const ConfirmOrder: React.FC = () => {
  const [buyerMessage,setBuyerMessage] = useState('')
  const [data,setData] = useState({})
  const [pointsTotal,setPointsTotal] = useState(0)
  let [count,setCount] = useState(1)
  // let [loadFlag,setLoadFlag] =useState(false)
  useEffect(() => {
    const token = getParamsNew('token')
    if(token !== null && token !== undefined) sessionStorage.setItem('token', token)
    const id = getParamsNew('id')
    const params = {
      id:id
    }
    get('/product/selectByPrimaryKey',params).then(res => {
      setData(res.data)      
    })
  },[])
  useEffect(()=> {
    post('/user/queryAccrue').then(res => {
      if(res.success) {
        setPointsTotal(res.data.pointsTotal)
      } else {
        Toast.show({
          content: '系统繁忙',
          position: 'top',
        })
      }
    })
  },[])

    return(
      <div className="confirmOrder">
        <div className="confirmOrder-main">
            <div className="confirmOrder-detail">
                <div className="confirmOrder-detailContent">
                    <div className="confirmOrder-leftImg">
                        <img src={data.firstPicture} alt="" />
                    </div>
                    <div className="confirmOrder-rightInfo">
                        <div className="confirmOrder-goodsName">
                            {data.name}
                        </div>
                        <div className="confirmOrder-goodsConfig">
                            <span>白色+贴纸</span>
                            <span>数量：{count}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="confirmOrder-orderConfig">
                <div className="confirmOrder-orderParams">
              <div ><span>商品价格</span>  <span className="confirmOrder-PriceNum">{data.minPoints}石榴籽</span> </div>
              <div><span>我的石榴籽</span>  <span className="confirmOrder-PriceNum">{pointsTotal}石榴籽</span></div>
              <div><span>本次可抵扣</span>  <span className="confirmOrder-PriceNum">{data.minPoints < pointsTotal ? data.minPoints : pointsTotal}石榴籽</span></div>
                    {/* <div><span>运费</span>  <span className="confirmOrder-PriceNum">{data.minPoints}石榴籽</span></div> */}
                </div>
                <div className="confirmOrder-money">
                    <span className="pay-money">
                所需石榴籽
                    </span>
              <span className="confirmOrder-count">{data.minPoints}石榴籽</span>
                </div>
            </div>
            <div className="confirmOrder-notes">
                <span className="confirmOrder-notesInfo">备注信息</span>
                <TextArea
                  placeholder='选填，请填写或上传备注信息内容…'
                  className="confirmOrder-textarea"
                  value={buyerMessage}
                  onChange={val => {
                    setBuyerMessage(val)
                  }}
                />
            </div>
        </div>
        <div className="confirmOrder-footer">
            <div className="confirmOrder-total">
            <div className="confirmOrder-totalText"> <span className="confirmOrder-totalAll" >共计：</span>  <span className="confirmOrder-totalBriPrice" >{data.minPoints}石榴籽</span></div>
            </div>
            <div className="confirmOrder-submitOrderBox">
              <div className="confirmOrder-submitOrder" onClick={subOrder}>提交订单</div>
            </div>
        </div>
        {/* {
          loadFlag ? <span className="loading" style={{ fontSize: '48px' }}>
          订单提交中<DotLoading   />
        </span> : null
        } */}
      </div>
    )
    function subOrder() {
      if(data.skuList[0].stock > count){
        // setLoadFlag(true)
        if (data.minPoints < pointsTotal) {
          const query = {
            buyerMessage:buyerMessage,
            orderVos:[{
              productSkuId: data.skuList[0].id,
              count: 1,
            }]
          }
      post(`/orderManager/beforeCheckOrder?productSkuId=${data.skuList[0].id}&count=1`).then(res => {
        }).then(() => {
      post(`/orderManager/checkOrder?productSkuId=${data.skuList[0].id}&count=1`).then(res => {
      }).then(() => {
      post(`/orderManager/submitOrder`,query).then(res => {
        if(res.success){
          // setLoadFlag(false)
          Toast.show({
            content: <div style={{width:'180px',height:'80px',fontSize: '32px',display:'flex',alignItems:'center',justifyContent:'center'}}>订单已提交</div>,
            maskClickable: false,
            duration: 1000,
            position: 'top',
            afterClose: (() => {const obj = {
              "type": "orderList",
            }
            const newObj = JSON.stringify(obj)
            window.jsObj.sendJumpInfo(newObj);
          })
          })
            } else {
              Toast.show({
                content: <div style={{width:'220px',height:'80px',fontSize: '32px',display:'flex',alignItems:'center',justifyContent:'center'}}>订单提交失败</div>,
                maskClickable: false,
                duration: 1000,
                position: 'top'})
                  }
          })
        })
      })
    } else {
      Toast.show({
        content: <div style={{ width: '180px', height: '80px', fontSize: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>石榴籽不足</div>,
        maskClickable: false,
        duration: 1000,
        position: 'top'
      })
    }
      } else {
        Toast.show({
                content: <div style={{width:'220px',height:'80px',fontSize: '32px',display:'flex',alignItems:'center',justifyContent:'center'}}>商品库存不够</div>,
                maskClickable: false,
                duration: 1000,
                position: 'top'
              })
      }

    }
}
export default ConfirmOrder;