import React from 'react';
import { Swiper } from 'antd-mobile';
import './index.scss'
const SwiperImg : React.FC = (props) => {
    const items = props &&props.imgArr.map((item, index) => (
        <Swiper.Item key={index}>
            <img
              style={{ width: '100%', height: '100%', }}
              src={item && item.refererFilePath ? item.refererFilePath : item}
              alt=""
            >
          </img>         
        </Swiper.Item> 
      ))
    return(
        <div className='swiper-box'>
                {/* indicator={()=> null} */}
            <Swiper
                style={{ width: '100%', height: '100%', borderRadius:`${props.borderRadius}` }}
                loop
                autoplay
            >{items}</Swiper>
        </div>
    );
}
export default SwiperImg;