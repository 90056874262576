import MallHomePage from '../page/MallHomePage/index' // 商品首页
import GoodsDetail from "../page/GoodsDetail/index"; // 商品详情
import ConfirmOrder from "../page/ConfirmOrder"; // 确认订单
import RevitalizationMall from '../page/RevitalizationMall' // 振兴商城
import MenuType from '../page/MenuType' // 全部分类
import PublicWelfare from '../page/PublicWelfare'
import MenuList from '../page/MenuList'; // 分类列表
const routes = [
    {
        path: "/MallHomePage",
        component: MallHomePage,
    },
    {
        path: "/RevitalizationMall",
        component: RevitalizationMall,
    },
    {
        path: "/GoodsDetail",
        component: GoodsDetail,
        title: "商品详情"
    },
    {
        path: "/MenuType",
        component: MenuType,
        title: '全部分类'
    },
    {
        path: "/MenuList",
        component: MenuList,
        title: '分类列表'
    },
    {
        path: "/PublicWelfare",
        component: PublicWelfare,
        title: '公益榜'
    },
    {
        path: "/ConfirmOrder",
        component: ConfirmOrder,
        title: "确认订单"
    },
    {
        path: "/",
        to: "/MallHomePage"  // 重定向
    }
]
 
 
export default routes;