import React, {useState, useEffect} from "react";
import getParamsNew from '../../utils/getUrlParams'
import {  post } from '../../api/api.js'
import { Empty } from 'antd-mobile'
import MasonryLayout from '../GoodsConfig/masonry/index'
import './index.scss'

const MenuList = () => {
  let [totalPages, setTotalPages] = useState(null)
  let [currData, setCurrData] = useState([])
  let [shopData, setShopData] = useState([])
  let [currentPage, setCurrentPage] = useState(1);
  // 发起请求获取分页数据
  async function fetchProducts() {
    const catalogId = getParamsNew('id');
    const res = await post(`/product/queryExtDataListPage?catalogId=${catalogId}&status=UP&currentPage=${currentPage}`)
    const data = res.data;
    const total = res.count;
    setTotalPages(total)
    setCurrData(data)
    setShopData((shopData) => [...shopData, ...data]);
  };
  // 第一次拉取分页信息
  useEffect(() => {
    fetchProducts()
  }, [])
  // 监听scroll事件
  useEffect(() => {
    let MallHomePage = document.querySelector('.menuList-box')
    MallHomePage.addEventListener('scroll', handleScroll);
    return () => {
      MallHomePage.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // 判断是否滚动到页面底部
  const handleScroll = () => {
    let MallHomePage = document.querySelector('.menuList-box')
    const { scrollTop, clientHeight, scrollHeight } = MallHomePage;
    if (Math.round(scrollTop + clientHeight) >= scrollHeight) {

      if ((currentPage - 1) * 10 + currData.length == totalPages) {
      } else {
        setCurrentPage(currentPage++);
        fetchProducts()
      }

    }
  };
    return(
      <div className="menu-list">
        <div className="menuList-box">
          {
            shopData.length <= 1 ? <Empty description='暂无数据' /> :
              <MasonryLayout data={shopData}></MasonryLayout>
          }
        </div>  
      </div>
    )
}
export default MenuList